.App {
  text-align: center;
}

.carousel-inner-home {
  height: 100vh;
  text-align: center;
}
.carousel-inner-tours {
  height: 25vh;
  text-align: center;
}

.nav-link {
  text-transform: uppercase;
  letter-spacing: 2px;
}
.nav-link:hover {
  color: #000;
}

.carousel-item {
  height: 100%;
}

.round {    
  width: 170px;
  height: 170px;
  border-radius: 100%;
}

.scenic {
  background-image: url("./pages/images/bg.png"), linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0) );
  background-blend-mode: color;
  background-size: cover;
  background-position: center center;
}

.round img {
  height: 70%;
  width: 70%;
  border-radius: 150px;
}

nav {
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(8px);
}

.carousel-item img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.heading {
  height: 100%;
  text-align: center;
  width: 100%;
  position: absolute;
  background: radial-gradient(rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0));
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading h1 {
  color: white;
  text-shadow: 0px 0px 4px rgb(0, 0, 0);
  line-height: 1.5;
  font-size: 34px;
  width: 100%;
}